import React from 'react'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'
import GoogleMapReact from 'google-map-react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import * as dateFormat from 'dateformat'
import Box from '../components/Box'
import PropertyLabel from '../components/PropertyLabel'
import ErrorMessage from '../components/ErrorMessage'
import MachineStatusBadge from '../components/MachineStatusBadge'
import { history } from '../store'
import MachineMarker from '../components/MachineMarker'
import RemoteActionsList from '../components/RemoteActionsList'
import MachineStockTable from '../components/MachineStockTable'
import MoneyTable from '../components/MoneyTable'
import MachinePromoterFeesTable from '../components/MachinePromoterFeesTable'

import RemoteActionAddForm from '../forms/RemoteActionAddForm'
import { deleteMachine, fetchMachine } from '../actions/machines'
import { getMachinePicture, getMachineCoordinates } from '../utils/machines'
import { fetchRemoteActions } from '../actions/remoteactions'
import { GMAPS_KEY } from '../config'
import SecurityBoxTable from '../components/SecurityBoxTable'

import { fetchMatrixs } from '../actions/matrixs'
import MatrixConstructor from '../components/MatrixConstructor'
import MatrixConstructorAdmin from '../components/MatrixConstructorAdmin'
import MatrixConstructorSignal from '../components/MatrixConstructorSignal'
import MatrixConstructorBattery from '../components/MatrixConstructorBattery'
import MatrixConstructorVersion from '../components/MatrixConstructorVersion'
import MatrixConstructorBLEOff from '../components/MatrixConstructorBLEOff'
import AssignedModuleList from '../components/AssignedModuleList'
import AssignedPairingList from '../components/AssignedPairingList'
import AssignedPaymentDevicesList from '../components/AssignedPaymentDevicesList'


class MachineDetailsView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loaded: false,
      deleting: false,
      deleteConfirmShown: false,
      showModules: false,
      showPairing: false,
      showPaymentDevices: false,
    };

    this.delete = this.delete.bind(this)
    this.deleteConfirmShow = this.deleteConfirmShow.bind(this)
    this.deleteConfirmToggle = this.deleteConfirmToggle.bind(this)
    this.downloadAgenczia = this.downloadAgenczia.bind(this)

    this.createMatrix = this.createMatrix.bind(this)
  }

  goTo(e) {
    history.push(`/matrixs/:Serial`)
  }

  componentDidMount() {
    const { fetchMachine, fetchRemoteActions, fetchMatrixs, match } = this.props

    fetchMachine(match.params.Serial)
    fetchMatrixs('')
    fetchRemoteActions(match.params.Serial)

  }

  componentWillReceiveProps(nextProps) {
    if (this.state.deleting && !nextProps.machines.machine) {
      this.props.push('/machines')
    }

  }


  delete() {
    const { deleteMachine, machines: { machine } } = this.props

    this.setState({ deleting: true })
    deleteMachine(machine.Serial)
  }

  deleteConfirmShow() {
    this.setState({ deleteConfirmShown: true })
  }

  deleteConfirmToggle() {
    this.setState({ deleteConfirmShown: !this.state.deleteConfirmShown })
  }

  downloadAgenczia() {
    const { machines: { machine } } = this.props

    const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(machine.Agenczia_della_entrate.data));
    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", 'agenczia_della_entrate' + ".json");
    document.body.appendChild(downloadAnchorNode);
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }

  getPayments(tipo) {

    switch (tipo) {
      case 0: {
        return "TPV + CASH"
      }
      case 1: {
        return "CASH"
      }
      case 2: {
        return "TPV"
      }
      case 3: {
        return "RFID"
      }
    }

  }

  createMatrix() {

    const { matrixs, machines: { machine }, error, loading } = this.props

    if (!matrixs) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    return (

      <MatrixConstructor matrixs={matrixs} machine={machine} />

    )

  }

  createMatrixAdmin() {

    const { matrixs, machines: { machine }, error, loading } = this.props

    if (!matrixs) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    return (

      <MatrixConstructorAdmin matrixs={matrixs} machine={machine} />

    )

  }

  createMatrixBattery() {

    const { matrixs, machines: { machine }, error, loading } = this.props

    if (!matrixs) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    return (

      <MatrixConstructorBattery matrixs={matrixs} machine={machine} />

    )

  }

  createMatrixSignal() {

    const { matrixs, machines: { machine }, error, loading } = this.props

    if (!matrixs) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    return (

      <MatrixConstructorSignal matrixs={matrixs} machine={machine} />

    )

  }

  createMatrixVersion() {

    const { matrixs, machines: { machine }, error, loading } = this.props

    if (!matrixs) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    return (

      <MatrixConstructorVersion matrixs={matrixs} machine={machine} />

    )

  }

  createMatrixBLEOff() {

    const { matrixs, machines: { machine }, error, loading } = this.props

    if (!matrixs) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    return (

      <MatrixConstructorBLEOff matrixs={matrixs} machine={machine} />

    )

  }

  render() {
    const { matrix, role, type, machines, machines: { machine }, remoteActions } = this.props

    const locker = 1;

    if (machines.error) {
      return (
        <ErrorMessage message={machines.error.message} />
      )
    }

    if (!machine || machines.loading) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    const coordinates = getMachineCoordinates(machine.Location)

    return (
      <div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <h1>
              <span className="text-vw-light">ONE </span><span className="text-vw-dark">{machine.Serial}</span>
            </h1>
          </div>
          {role === 'ADMIN' && (type === 'ROOT' || type === 'GOD') &&
            <div className="col-xs-12 col-sm-6 col-md-6 text-right align-items-center d-flex justify-content-center">
              <div className="col d-flex justify-content-center">
                <Link to={`/machines/${machine.Serial}/edit`}>
                  <button className="btn bg-vw-light text-white">
                    <i className="fas fa-edit"></i>
                    <span className="d-none d-sm-inline ml-1">Editar</span>
                  </button>
                </Link>
              </div>
              {/*<button className="btn btn-danger mr-1" onClick={this.deleteConfirmShow}>
                <i className="fas fa-trash mr-1"></i>Borrar
              </button>*/}
            </div>
          }
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-4">
            <Box title="Estado" icon="cubes">
              <PropertyLabel name="Última conexión"
                value={(new Date(machine.Last_signal)).toLocaleString('es-ES', { timeZone: 'Europe/Madrid', hour12: false })} />
              <img src={getMachinePicture(machine.Type)} alt="Máquina" width="35%" style={{ marginLeft: '20%' }} />
              <MachineStatusBadge machine={machine} />
            </Box>
          </div>
          <div className="col-xs-12 col-md-4">
            <Box title="Detalles" icon="cubes">
              <PropertyLabel name="Número de serie"
                value={machine.Serial} />
              <PropertyLabel name="Tipo"
                value={machine.Type} />
              <PropertyLabel name="Password máquina"
                value={machine.PasswordMachine} />
              <PropertyLabel name="Password cash"
                value={machine.PasswordCash} />
              <PropertyLabel name="Inicio del modo sleep (h)"
                value={machine.SleepStart} />
              <PropertyLabel name="Fin del modo sleep (h)"
                value={machine.SleepEnd} />
              <PropertyLabel name="Cambio inicial (EUR)"
                value={machine.ChangeInitial} />
              <PropertyLabel name="Última venta"
                value={machine.LastSale} />
              <PropertyLabel name="Última edición"
                value={machine.Executor} />
            </Box>
          </div>
          <div className="col-xs-12 col-md-4">
            <Box title="Asignación" icon="arrows-alt-h">
              {!machine.Customer ? (
                <h6>machine is not allocated</h6>
              ) : (
                <div>
                  <PropertyLabel name="Cliente"
                    value={machine.Customer.Fullname} />
                  <PropertyLabel name="Contrato #"
                    value={machine.Contract.number} />
                  {coordinates &&
                    <div className="mb-3" style={{ width: '100%', height: '210px' }}>
                      <GoogleMapReact
                        bootstrapURLKeys={{ key: GMAPS_KEY }}
                        defaultCenter={coordinates}
                        defaultZoom={10}
                      >
                        <MachineMarker
                          lat={coordinates.lat}
                          lng={coordinates.lng}
                          machine={machine}
                        />
                      </GoogleMapReact>
                    </div>
                  }
                </div>
              )}
            </Box>
          </div>
        </div>
        {role === 'ADMIN' &&
          <Box title="Firmware Settings" icon="cubes">
            <div className="row">
              <div className="col-xs-12 col-md-4">
                <Box title="Robot" icon="cubes">
                  <PropertyLabel name="Robotctl_up_init_speed"
                    value={machine.Robotctl_up_init_speed} />
                  <PropertyLabel name="Robotctl_empty_steps"
                    value={machine.Robotctl_empty_steps} />
                  <PropertyLabel name="Robotctl_steps_100_covers"
                    value={machine.Robotctl_steps_100_covers} />
                  <PropertyLabel name="Robotctl_pickup_movement"
                    value={machine.Robotctl_pickup_movement} />
                  <PropertyLabel name="Robotctl_detect_min_pressure"
                    value={machine.Robotctl_detect_min_pressure} />
                </Box>
              </div>
              <div className="col-xs-12 col-md-4">
                <Box title="Tapa" icon="cubes">
                  <PropertyLabel name="Lidctl_st_maxopen_move"
                    value={machine.Lidctl_st_maxopen_move} />
                  <PropertyLabel name="Lidctl_max_move"
                    value={machine.Lidctl_max_move} />
                </Box>
              </div>
              <div className="col-xs-12 col-md-4">
                <Box title="Sellado" icon="cubes">
                  <PropertyLabel name="Sealctl_min_press_kpa"
                    value={machine.Sealctl_min_press_kpa} />
                  <PropertyLabel name="Sealctl_pump_init"
                    value={machine.Sealctl_pump_init} />
                  <PropertyLabel name="Sealctl_st_close_move"
                    value={machine.Sealctl_st_close_move} />
                  <PropertyLabel name="Sealctl_seal_intensity"
                    value={machine.Sealctl_seal_intensity} />
                </Box>
              </div>
            </div>
          </Box>
        }
        {role === 'ADMIN' && machine.PaymentMethods == 3 &&
          <Box title="Aquapalace Settings" icon="cubes">
            <div className="row">
              <div className="col-xs-12 col-md-4">
                <Box title="Máquina" icon="cubes">
                  <PropertyLabel name="Endpoint"
                    value={machine.Aquapalace.Endpoint} />
                  <PropertyLabel name="Username"
                    value={machine.Aquapalace.Username} />
                  <PropertyLabel name="Password"
                    value={machine.Aquapalace.Password} />
                  <PropertyLabel name="ExternalCashDeskId"
                    value={machine.Aquapalace.ExternalCashDeskId} />
                  <PropertyLabel name="ExternalSystemId"
                    value={machine.Aquapalace.ExternalSystemId} />
                  <PropertyLabel name="ComputerId"
                    value={machine.Aquapalace.ComputerId} />
                  <PropertyLabel name="DepartmentId"
                    value={machine.Aquapalace.DepartmentId} />
                </Box>
              </div>
              <div className="col-xs-12 col-md-4">
                <Box title="Venta" icon="cubes">
                  <PropertyLabel name="BagType"
                    value={machine.Aquapalace.BagType} />
                  <PropertyLabel name="ItemType"
                    value={machine.Aquapalace.ItemType} />
                  <PropertyLabel name="PackArticleId"
                    value={machine.Aquapalace.PackArticleId} />
                  <PropertyLabel name="LayArticleId"
                    value={machine.Aquapalace.LayArticleId} />
                  <PropertyLabel name="GoArticleId"
                    value={machine.Aquapalace.GoArticleId} />
                  <PropertyLabel name="PaymentType"
                    value={machine.Aquapalace.PaymentType} />
                  <PropertyLabel name="PayTipeId"
                    value={machine.Aquapalace.PayTipeId} />
                  <PropertyLabel name="PriceUnit"
                    value={machine.Aquapalace.PriceUnit} />
                </Box>
              </div>
              <div className="col-xs-12 col-md-4">
                <Box title="Otros" icon="cubes">
                  <PropertyLabel name="UserId"
                    value={machine.Aquapalace.UserId} />
                </Box>
              </div>
            </div>
          </Box>
        }
        {role === 'ADMIN' && machine.PaymentMethods == 3 &&
          <Box title="Land of Legends Settings" icon="cubes">
            <div className="row">
              <div className="col-xs-12 col-md-12">
                <Box title="Bearer" icon="cubes">
                  <PropertyLabel name="Bearer"
                    value={machine.LandOfLegends.Bearer} />
                </Box>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-md-6">
                <Box title="Máquina" icon="cubes">
                  <PropertyLabel name="Endpoint"
                    value={machine.LandOfLegends.Endpoint} />
                  <PropertyLabel name="DEPID"
                    value={machine.LandOfLegends.DEPID} />
                  <PropertyLabel name="PORTALID"
                    value={machine.LandOfLegends.PORTALID} />
                  <PropertyLabel name="WAITERID"
                    value={machine.LandOfLegends.WAITERID} />
                </Box>
              </div>
              <div className="col-xs-12 col-md-6">
                <Box title="Venta" icon="cubes">
                  <PropertyLabel name="PRODUCTID_VWPACK"
                    value={machine.LandOfLegends.PRODUCTID_VWPACK} />
                  <PropertyLabel name="PRODUCTID_VWLAY"
                    value={machine.LandOfLegends.PRODUCTID_VWLAY} />
                  <PropertyLabel name="PRODUCTID_VWGO"
                    value={machine.LandOfLegends.PRODUCTID_VWGO} />
                </Box>
              </div>
            </div>
          </Box>
        }
        <Box title="Niveles de Consumibles" icon="archive">
          <MachineStockTable machine={this.props.machines.machine} />
        </Box>
        {
          (machine.Promoter) &&
          <Box title="Promotor" icon="user" class="row">
            <PropertyLabel name="Nombre"
              value={`${machine.Promoter.name} ${machine.Promoter.surname}`} />
            <PropertyLabel name="Código"
              value={machine.Promoter.password} />
          </Box>
        }
        {role === 'ADMIN' &&
          <div className="row">
            <div className="col-xs-12 col-md-4">
              <Box title="Info Admin" icon="cubes">
                <PropertyLabel name="Token"
                  value={machine.Token} />
                <PropertyLabel name="Password master"
                  value={machine.PasswordMaster} />
                <PropertyLabel name="Fecha de fabricación"
                  value={(new Date(machine.Build_date)).toLocaleTimeString('es-ES', { timeZone: 'Europe/Madrid', hour12: false })} />
                <PropertyLabel name="Versión del software"
                  value={machine.Sw_version} />
                <PropertyLabel name="Versión del firmware"
                  value={machine.Fw_version} />
                <PropertyLabel name="Versión del updater"
                  value={machine.Up_version} />
              </Box>
              <Box title="Config Machine Admin" icon="cubes">
                <PropertyLabel name="Métodos de pago"
                  value={this.getPayments(machine.PaymentMethods)} />
                <PropertyLabel name="Idioma principal"
                  value={machine.Language} />
                {machine.videoAllowed &&
                  <PropertyLabel name="Video en pantalla"
                    value={machine.videoAllowed ? 'SI' : 'NO'} />
                }
                {machine.Reset_no_internet &&
                  <PropertyLabel name="Reset sin conectividad"
                    value={machine.Reset_no_internet ? 'SI' : 'NO'} />
                }
                {machine.Enable_standby &&
                  <PropertyLabel name="Habilitar modo stand-by"
                    value={machine.Enable_standby ? 'SI' : 'NO'} />
                }
                {machine.EthernetMode &&
                  <PropertyLabel name="Máquina con Ethernet"
                    value={machine.EthernetMode ? 'SI' : 'NO'} />
                }
                {machine.BubblesSoundActive &&
                  <PropertyLabel name="Claim burbujas"
                    value={machine.BubblesSoundActive ? 'SI' : 'NO'} />
                }
                {machine.WaterproofSoundActive &&
                  <PropertyLabel name="Claim waterproof"
                    value={machine.WaterproofSoundActive ? 'SI' : 'NO'} />
                }
                {machine.GeneralSoundActive &&
                  <PropertyLabel name="Pasos sonoros"
                    value={machine.GeneralSoundActive ? 'SI' : 'NO'} />
                }
                <PropertyLabel name="Timeout pantallas"
                  value={machine.AutoScreenExitTime} />
                {machine.PackSale &&
                  <PropertyLabel name="Venta en formato packs"
                    value={machine.PackSale ? 'SI' : 'NO'} />
                }
              </Box>
            </div>
            <div className="col-xs-12 col-md-4">
              <Box title="Sleep Machine Admin" icon="cubes">
                <PropertyLabel name="Inicio del modo sleep (h)"
                  value={machine.SleepStart} />
                <PropertyLabel name="Fin del modo sleep (h)"
                  value={machine.SleepEnd} />
              </Box>
              <Box title="Config Pagos Admin" icon="dollar-sign">
                <PropertyLabel name="Métodos de pago"
                  value={machine.PaymentMethods} />
                <PropertyLabel name="Billetes aceptados"
                  value={machine.BillerAcceptance} />
                <PropertyLabel name="Cambio inicial (€)"
                  value={machine.ChangeInitial} />
                <PropertyLabel name="Caja de seguridad"
                  value={(machine.SecurityBox).toString()} />
                <PropertyLabel name="Máx devo diario (€)"
                  value={machine.LimiteDevolucionDiario} />
                <PropertyLabel name="Máx devo de golpe (€)"
                  value={machine.LimiteDevolucion} />
                <PropertyLabel name="Cobro máximo Cash (€)"
                  value={machine.CashMax} />
              </Box>
            </div>
            <div className="col-xs-12 col-md-4">
              <Box title="PAYTEF" icon="dollar-sign">
                <PropertyLabel name="Número de serie"
                  value={machine.Tpv_serial} />
                <PropertyLabel name="TCOD"
                  value={machine.Tpv_tcod} />
                <PropertyLabel name="Cargo máximo TPV(€)"
                  value={machine.Tpv_max} />
                <PropertyLabel name="Timeout de cancelación"
                  value={machine.TpvCancelTime} />
              </Box>
              <Box title="REDSYS" icon="cubes">
                <PropertyLabel name="Código de comercio"
                  value={machine.TpvBusinessCode} />
                <PropertyLabel name="Número de Terminal"
                  value={machine.TpvTerminalCode} />
                <PropertyLabel name="Clave de Firma"
                  value={machine.TpvFirmKeyCode} />
              </Box>
              <Box title="Config Pagos Token" icon="dollar-sign">
                {machine.TokenActivated &&
                  <PropertyLabel name="Token activado"
                    value={(machine.TokenActivated).toString()} />
                }
                {machine.TokenActivated &&
                  <PropertyLabel name="Valor del token (€)"
                    value={machine.Token_value} />
                }
                {machine.TokenActivated &&
                  <PropertyLabel name="Valor de venta (€)"
                    value={machine.Token_sale_value} />
                }
              </Box>
              <Box title="Dispositivos de pago" icon="door-closed">
                <Button color="primary mr-1" style={{ marginBottom: '20px' }} onClick={() => { this.setState({ showPaymentDevices: !this.state.showPaymentDevices }) }}>{this.state.showPaymentDevices ? 'Ocultar' : 'Mostrar'} Dispositivos</Button>
                {
                  this.state.showPaymentDevices ? <AssignedPaymentDevicesList Devices={this.props.machines.machine.PaymentDevices} /> : null
                }
              </Box>
            </div>
          </div>
        }
        {machine.ScanNightAllowed ? (
          role === 'ADMIN' ? (
            <Box title="Matriz" icon="arrows-alt-h">
              <div id="matrixScroll">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '1' })}
                      onClick={() => { this.toggle('1'); }}
                    >
                      Status
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '2' })}
                      onClick={() => { this.toggle('2'); }}
                    >
                      Bluetooth
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '3' })}
                      onClick={() => { this.toggle('3'); }}
                    >
                      Battery
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '4' })}
                      onClick={() => { this.toggle('4'); }}
                    >
                      Signal
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '5' })}
                      onClick={() => { this.toggle('5'); }}
                    >
                      Version
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '6' })}
                      onClick={() => { this.toggle('6'); }}
                    >
                      Power Down
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    <br></br>
                    {this.createMatrix()}
                  </TabPane>
                  <TabPane tabId="2">
                    <br></br>
                    {this.createMatrixAdmin()}
                  </TabPane>
                  <TabPane tabId="3">
                    <br></br>
                    {this.createMatrixBattery()}
                  </TabPane>
                  <TabPane tabId="4">
                    <br></br>
                    {this.createMatrixSignal()}
                  </TabPane>
                  <TabPane tabId="5">
                    <br></br>
                    {this.createMatrixVersion()}
                  </TabPane>
                  <TabPane tabId="6">
                    <br></br>
                    {this.createMatrixBLEOff()}
                  </TabPane>
                </TabContent>
              </div>
            </Box>
          ) : (
            <div className="row">
              <div className="col-xs-12 col-md-12">
                <Box title="Matriz" icon="arrows-alt-h">
                  {this.createMatrix()}
                </Box>
              </div>
            </div>
          )
        ) : (<div className="row"></div>)
        }
        <Box title="CAJAS" icon="dollar-sign">
          <MoneyTable machine={this.props.machines.machine} />
        </Box>
        <Box title="Caja de seguridad" icon="dollar-sign">
          <SecurityBoxTable machine={this.props.machines.machine} />
        </Box>
        {role === 'ADMIN' && (type === 'ROOT' || type === 'GOD') &&
          <Box title="Acciones Remotas" icon="hand-paper">
            <RemoteActionAddForm machine={machine} />
            <RemoteActionsList remoteActions={remoteActions} />
          </Box>
        }
        {role === 'ADMIN' && (type === 'ROOT' || type === 'GOD') &&
          <Box title="Agenzia della entrate" icon="hand-paper">
            {
              (machine.Agenczia_della_entrate && machine.Agenczia_della_entrate.length)
            }

            {
              (machine.Agenczia_della_entrate && machine.Agenczia_della_entrate.date) &&
              <div>
                <hr />
                <h6 className="text-vw-dark">AGENZIA DELLA ENTRATE</h6>
                <PropertyLabel name="Fecha"
                  value={
                    machine.Agenczia_della_entrate ? (new Date(machine.Agenczia_della_entrate.date)).toLocaleTimeString('es-ES', { timeZone: 'Europe/Madrid', hour12: false }) : ''
                  } />
                <div>
                  <div className="d-flex justify-content-between">
                    <h6>Descargar:</h6>
                    <span className="text-muted cursor-pointer" onClick={this.downloadAgenczia}>
                      <i className={`fas fa-download float-right mr-1`}></i>
                    </span>
                  </div>
                </div>
              </div>
            }
          </Box>
        }

        {/* Confirm deletion */}
        <div>
          <Modal isOpen={this.state.deleteConfirmShown}
            toggle={this.deleteConfirmToggle}>
            <ModalHeader toggle={this.deleteConfirmToggle}>
              Confirmar
            </ModalHeader>
            <ModalBody>
              {machine.Customer ? (
                <p>Una máquina no se puede borrar mientras tenga un contrato asignado.</p>
              ) : (
                <p>¿Estás seguro de borrar la máquina?</p>
              )}
            </ModalBody>
            <ModalFooter>
              {!machine.Customer &&
                <Button color="primary mr-1" onClick={this.delete}>
                  Borrar
                </Button>}
              <Button color="secondary" onClick={this.deleteConfirmToggle}>
                Cancelar
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  machines: state.machines,
  matrixs: state.matrixs.matrixs,
  error: state.machines.error,
  loading: state.machines.loading,
  remoteActions: state.remoteactions,
  role: state.auth.role,
  type: state.auth.type,
})

const mapDispatchToProps = dispatch => ({
  deleteMachine: bindActionCreators(deleteMachine, dispatch),
  fetchMachine: bindActionCreators(fetchMachine, dispatch),
  fetchMatrixs: bindActionCreators(fetchMatrixs, dispatch),
  fetchRemoteActions: bindActionCreators(fetchRemoteActions, dispatch),
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(MachineDetailsView)
